import { FaCircleNotch } from 'react-icons/fa';

export default function Loading(props: { className?: string }) {
  return (
    <div
      className={
        'absolute bottom-1/2 left-1/2 -translate-x-1/2 ' + props.className
      }
    >
      <FaCircleNotch className="m-auto animate-spin" size={30} />
    </div>
  );
}
